<template>
  <div >
    <img src="../../assets/mmexport1646206287440_[B@128e285.jpg" alt="">
  </div>
</template>

<script>
export default {

}
</script>

<style lang='less' scoped>
   div {
     width: 100%;
     height: 100%;
     img {
       width: 100%;
       height: 100%;
     }
   }
</style>